import React from 'react';
import { Link, HeadingLink, LinkArrow, HeadingLevel2 } from '../ui';
import styled, { css } from '../../styled-components';
import { media, pixelToRem } from '../../utilities';
import { LineTitle } from '../LineTitle/LineTitle.component';

interface IContentSummaryProps extends wmw.IBaseStyledComponentProps {
    title: string;
    summary: string;
    type: string;
    image?: {
        src: string;
        shapeOutline?: string;
        alt: string;
    };
    slug?: string;
    highlightColour?: 'electricBlue' | 'green';
    imageAlign?: 'left' | 'right';
}

const Heading = styled(HeadingLevel2)`
    ${media.xl`
        margin-bottom: 1.5rem;
    `}
`;

const UnstyledContentSummary: React.SFC<IContentSummaryProps> = ({
    title,
    summary,
    type,
    image,
    slug,
    highlightColour = 'electricBlue',
    className
}) => (
    <article className={className}>
        {image && (
            <img
                data-testid="contentSummaryImage"
                src={image.src}
                alt={image.alt}
                className="summary-image"
            />
        )}

        <LineTitle data-testid="contentSummaryType">{type}</LineTitle>

        <Heading data-testid="contentSummaryTitle">
            {slug && (
                <LinkArrow
                    to={slug}
                    inline={true}
                    color={highlightColour}
                    arrowColor={highlightColour}
                >
                    {title}
                </LinkArrow>
            )}
            {!slug && <span>{title}</span>}
        </Heading>

        <p data-testid="contentSummarySummary">{summary}</p>
    </article>
);

export const ContentSummary = styled(UnstyledContentSummary)`
    overflow: auto;

    ${({ imageAlign, image, highlightColour = 'electricBlue' }) => css`
        ${LineTitle} {
            color: ${({ theme }) => theme.colors[highlightColour].color};

            &:before {
                background-color: ${({ theme }) =>
                    theme.colors[highlightColour].color};
            }
        }

        ${Link} {
            &:after {
                background-color: ${({ theme }) =>
                    theme.colors[highlightColour].color};
            }
        }

        .summary-image {
            position: relative;
            max-width: 100%;
            width: 100%;
            margin-bottom: 1rem;
            z-index: 10;
            max-height: 400px;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                height: 140px;
                left: -${pixelToRem(30)};
                right: -${pixelToRem(30)};
                background-color: ${({ theme }) => theme.colors.pink.color};
                z-index: -1;
                transform: translateY(-50%);

                ${media.m`
                    right: -1rem;
                    left: -9999px;
                `}
            }

            ${media.s`
                max-width: 45%;
                width: auto;
                margin-bottom: 0;
                margin-left: ${imageAlign === 'right' ? '3rem' : '0'};
                margin-right: ${imageAlign === 'right' ? '0' : '3rem'};
                float: ${imageAlign === 'right' ? 'right' : 'left'};
                shape-outside: ${(image && `url(${image.shapeOutline})`) || ''};
                shape-margin: 4rem;
            `}

            ${media.l`
                max-width: 500px;
            `}

            &:before {
                ${imageAlign === 'right'
                    ? media.m`
                        right: -9999px;
                        left: -1rem;
                      `
                    : ''};

                background-color: ${({ theme }) =>
                    theme.colors[highlightColour || 'electricBlue'].color};
            }
        }

        p:last-child {
            margin: 0;
        }
    `}
`;
