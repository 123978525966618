import styled, { css } from '../../styled-components';
import { media } from '../../utilities';

interface ILineTitleProps {
    color?: wmw.ColorOptions;
}

export const LineTitle = styled.h5<ILineTitleProps>`
    ${({ theme, color }) => {
        function getColor() {
            return color
                ? theme.colors[color].color
                : theme.colors.deepBlue.color;
        }

        return css`
            position: relative;
            color: ${getColor()};
            margin-bottom: 0;
            font-size: ${theme.fontNew.primary.size.level6.size};
            font-weight: 400;
            display: inline-flex;
            align-items: center;

            &:before {
                content: '';
                display: block;
                margin-right: 8px;
                width: 2px;
                height: 24px;
                background-color: ${getColor()};
            }

            ${media.xl`
                font-size: ${theme.fontNew.primary.size.level5.size};
            `}
        `;
    }}
`;
